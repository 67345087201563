













































































































import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
import { mapMutations } from 'vuex';
import moment from 'moment';
import axios from 'axios';
import { Camera, Image } from '@/views/types';

const Ripple = require('vue-ripple-directive');

export default Vue.extend({
  components: {
    DatePicker,
  },
  directives: {
    Ripple,
  },

  data() {
    const range = [moment().subtract(1, 'days').valueOf(), moment().valueOf()];

    const columns = [
      {
        key: 'index',
        label: '序號',
      },
      {
        key: 'content',
        label: '圖片(點擊放大)',
      },
      {
        key: 'cameraId',
        label: '相機',
        formatter: (val: string) => {
          let camera = this.cameraList.find((c: Camera) => c._id === val);
          return camera ? camera.name : '';
        },
      },
      {
        key: 'createDate',
        label: '建立日期',
        sortable: true,
        formatter: (val: number) => {
          return moment(val).format('lll');
        },
      },
      {
        key: 'question',
        label: '問題',
      },
      {
        key: 'response',
        label: '回答',
      },
      {
        key: 'abnormal',
        label: '事件觸發',
        formatter: (value: any, key: any, item: Image) => {
          if (item.response === undefined) {
            return '辨識失敗';
          }

          let camera = this.cameraList.find(
            (c: Camera) => c._id === item.cameraId,
          );

          if (!camera || !camera.keywords) {
            return '未設定觸發關鍵字';
          }

          let keywords = camera.keywords.split(',');
          for (let keyword of keywords) {
            if (item.response.indexOf(keyword) !== -1) {
              return '是';
            }
          }

          return '否';
        },
      },
      {
        key: 'llm',
        label: '語言模型',
      },
    ];
    return {
      columns,
      form: {
        topic: 'all',
        cameras: [],
        range,
      },
      topics: [
        { val: 'all', txt: '全部' },
        { val: '車辨及車斗覆蓋', txt: '車辨及車斗覆蓋' },
        { val: '露天燃燒', txt: '露天燃燒' },
        { val: '河川揚塵', txt: '河川揚塵' },
        { val: '煙囪排放', txt: '煙囪排放' },
        { val: '工地揚塵', txt: '工地揚塵' },
        { val: '工廠逸散', txt: '工廠逸散' },
      ],
      cameraList: Array<Camera>(),
      display: false,
      rows: Array<Image>(),
      currentPage: 1,
    };
  },
  computed: {
    resultTitle(): string {
      let topic = this.topics.find(t => t.val === this.form.topic);
      return `主題:${topic?.txt}, 總共${this.rows.length}筆`;
    },
  },
  watch: {},
  async mounted() {
    await this.getCameraList();
    if (this.cameraList.length > 0) {
      this.form.cameras = [this.cameraList[0]._id];
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    async query() {
      this.setLoading({ loading: true });
      try {
        this.display = true;
        this.rows = [];
        const cameras = this.form.cameras.join(':');
        const url = `/ImageQuery/${cameras}/${this.form.topic}/${this.form.range[0]}/${this.form.range[1]}`;

        const ret = await axios.get(url);
        this.rows = ret.data;
      } catch (e) {
        console.error(e);
      } finally {
        this.setLoading({ loading: false });
      }
    },
    async getCameraList() {
      const res = await axios.get('/Cameras');
      if (res.status === 200) {
        this.cameraList = res.data;
      }
    },
    getImageUrl(id: string) {
      if (!id) return '';

      const baseUrl =
        process.env.NODE_ENV === 'development' ? 'http://localhost:9000/' : '/';

      return `${baseUrl}Image/${id}`;
    },
  },
});
